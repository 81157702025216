.multiselect {
    max-width: 1000px;
    width: 85%;
    margin: 20px auto;
}

.multiselect .title {
    display: block;
    font-size: 22px;
    text-align: left;
    font-weight: initial;
    margin: 0 auto;
    line-height: initial;
    font-family: var(--ion-font-family);
}
.multiselect ion-item {
    width: 100%;
    margin: 0 auto;
}

.multiselect.warning {
    color: red !important;

}
.multiselect.warning .checkbox {
    color: red;
    --border-color: red;
}
.multiselect.warning .checkbox ion-checkbox {
    --border-color: red;
}


@media screen and (max-width: 767px) {
    .multiselect {
        margin: 6vw auto;
    }

    .multiselect .title {
        font-size: 5.5vw;
        font-weight: bold;
        /* position: relative;
        left: 6vw;
        bottom: 0vw; */
        /* color: var(--text-color); */
        font-weight: initial;
        text-align: center;
        display: block;
        margin: 0 auto;
        line-height: 1;
    }

    .multiselect ion-item {
        margin: 0vw auto;
    }
}