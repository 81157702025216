.checkbox {
    width: 85%;
    max-width: 1000px;
    /* margin: 20px auto; */
    margin: 40px auto;
    /* border-bottom: 1px solid var(--input-element-color); */
    font-size: 18px;
    --color: var(--input-element-filled-color);
    --background: none;
    z-index: 0;
    --highlight-color-focused: var(--input-active-color);
    /* --border-width: 0 0 1px 0; */
    --border-width: 0 0 3px 0;
    --border-color: var(--input-element-color);
    --background-focused: none;
    /* font-size: 20px; */
}

.checkbox ion-label {
    white-space: unset !important;
    color: var(--input-element-color);
    z-index: 11;
    display: block;
    font-size: 31.25px !important;
    padding-top: 10px;
    text-transform: capitalize;
    pointer-events: none;
}

.checkbox ion-checkbox {
    --border-color: var(--input-element-color);
}

.checkbox.warning {
    color: red;
    --border-color: red;
}
.checkbox.warning ion-checkbox {
    --border-color: red;
}

@media screen and (max-width: 767px) {
    .checkbox {
        margin: 6vw auto;
        border-bottom: 1px solid var(--input-element-color);
        font-size: 5vw;
    }

    .checkbox ion-label {
        white-space: unset !important;
        color: var(--input-element-color);
        z-index: 11;
        display: block;
        font-size: calc(4vw * 1.25) !important;
        padding-top: 10px;
        text-transform: capitalize;
    }
}