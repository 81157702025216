.videoPlayer {
    background-color: black !important;
    background: black !important;
}

.videoPlayer ion-content {
    --background: transparent;
}

.videoPlayer .warning {
    background-color: white;
}

.videoPlayer .capture-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.videoPlayer .photo-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: block;
    z-index: 0;
}
.videoPlayer .cam-prev {
    margin: auto;
    display: block;
}
.videoPlayer .cam-cont {
    display: flex;
    height: 100%;
    background: black;
}
.videoPlayer .capture-container .prev-text {
    display: block;
    width: 70%;
    /* height: 80px; */
    margin: auto;
    text-align: center;
    font-size: 25px;
    padding-bottom: 20px;
    color: #ffffffff;
    }

.videoPlayer .capture-button {
    display: block;
    width: 80px;
    height: 80px;
    margin: auto;
    /* --border-radius: 50%; */
    border-radius: 50%;
    border: 5px solid #d9d6d6;
    background-color: #9a9c9e;
}

.videoPlayer .button-accept-photo {
    display: block;
    width: 80px;
    height: 80px;
    margin: auto;
    /* --border-radius: 50%; */
    border-radius: 50%;
    border: 5px solid red;
    background-color: yellow;
}


.videoPlayer .button-retake {
    position: absolute;
    margin-bottom: 20px;
    background-color: #ffffff;
    color: black;
    font-size: 24px;
    padding: 24px 36px;
    border-radius: 5px;
    text-align: center;
    z-index: 1;
    bottom: 0;
    left: 20%;
}