.signature {
  ion-content {
    width: auto;
    --background: var(--greetly-background-color);
  }
  .NDA-text {
    position: relative;
    margin: 0 4vw;
    text-align: justify;
    z-index: 1;
  }
  .signature-text {
    padding-top: 10px;
    grid-column: 2;
    margin: auto;
    display: block;
    width: fit-content;
    font-weight: bold;
    color: var(--text-color);
  }
  .signature-box {
    width: 95vw;
    height: 35vw;
    margin: 0 auto;
    border-bottom: solid 1px var(--common-gray);
  }
  .signature-below {
    ion-label {
      margin: 0;
      font-weight: bold;
      text-align: left;
      font-size: 5vw;
    }
  }
  .email-copy {
    width: fit-content;
    margin: 10vw auto;
    text-align: center;
    display: block;
    color: var(--common-gray);
  }
  .fullName {
    white-space: normal;
  }
  .signaturebox-header {
    width: 98vw;
    margin: auto;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
  .sign-canvas {
    border-bottom: 1px solid black;
    margin: 0 15px;
    touch-action: none;
  }
  .clearbtn {
    text-transform: capitalize;
    grid-column: 3;
    width: fit-content;
    --border-radius: 30px;
    --border-width: 1px;
    --border-style: solid;
    margin: auto;
  }
  .signature-above {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 33% 34% 33%;
    text-align: center;
    font-weight: bold;
    color: var(--text-color);
    .fullName {
      font-size: 20px;
    }
    .clearbtn {
      font-size: 20px;
      --border-radius: 0;
      --border-width: 0;
      --border-style: none;
      color: var(--ion-color-primary);
      --box-shadow: none;
    }
  }
  .ndaError {
    text-align: center;
    margin: 20px 0;
    color: red;
    font-size: 18px;
  }
}
.signature-below {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 600px) {
  .signature {
    #title {
      margin-top: 0;
      font-size: 18px;
      font-weight: 400;
    }
    ion-content {
      width: auto;
    }
    .title {
      font-size: 6.7vw;
      line-height: inherit;
      ion-label {
        white-space: unset;
      }
    }
    .NDA-text {
      margin: 0 4vw;
    }
    .signature-box {
      width: 95vw;
      height: 35vw;
    }
    .signature-text {
      margin: 2vw auto 2vw;
    }
    .signature-below {
      ion-label {
        text-align: left;
        font-size: 5vw;
      }
      display: flex;
      margin: 0 2.41vw;
      justify-content: space-around;
      margin-bottom: 40px;
    }
    .email-copy {
      margin: 10vw auto;
    }
    .fullName {
      font-size: 19px;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
    .signaturebox-header {
      width: 98vw;
      margin: auto;
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
    }
    .sign-canvas {
      border-radius: 0;
      margin: 0 15px;
      height: 15vh;
    }
  }
}

.signature-header {
  .home-button {
    top: 1.2rem;
    z-index: 10;
  }
}

/* Landscape IPAD */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .signature-header {
    .home-button {
      top: 2.5rem;
    }
  }
}
