.title {
  color: var(--color-header-text);
}

.brand-logo {
  width: auto;
  height: 100%;
  object-fit: contain;
  z-index: 1;
  max-height: 265px;
  max-width: 75%;
}

.body-mask {
  background: transparent;
  -webkit-mask-image: linear-gradient(to bottom, transparent 80%, black);
}
/* dark mode issue solve */
input {
  background: none;
}

input {
  background: none;
}

.app-screen-title-new-design.dark {
  margin-top: 48px;
  margin-bottom: 5px;
}

.app-screen-title-new-design.modern {
  margin-top: 43px;
  margin-bottom: 5px;
}

.app-screen-title-new-design.whimsical {
  margin-top: 23px;
  margin-bottom: 5px;
}

/* @TODO move to welcome after check all */

.img-container {
  /* width: 30%; */
  width: 45%;
  height: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  object-fit: contain;
  margin-top: 3vw;
  z-index: 1;
  max-height: 180px;
}
@media (orientation: landscape) {
  .img-container {
    margin-top: var(--image-container-margin-top-landscape) !important;
  }

  .img-container-new-design {
    width: 20%;
    object-fit: contain;
    margin-top: 20px;
  }
}
/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2) {
  .welcome-img-container {
    margin-top: var(--image-container-margin-top-landscape) !important;
  }
  .img-container {
    height: 20%;
  }
}

.gdpr-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 41px;
  --padding-start: 25px;
  text-align: center;
}
.provacy-poly-visit {
  font-weight: bold;
  color: var(--color-header-text);
}
.gdpr-cont a {
  text-decoration: none;
  font-weight: bold;
}

.copyright {
  color: var(--copyright-text-color);
  --background: none;
  display: block;
  text-align: center;
  width: 100%;
  --min-height: 20px;
  position: relative;
  /* bottom: 45px; */
  opacity: 0.5;
}

.white-button {
  --color: var(--input-element-color);
  --border-color: var(--input-element-color);
  --background: var(--ion-background-color);
  --background-hover: var(--option-button-background-hover);
  --background-focused: var(--option-button-background-focused);
  --background-activated: var(--option-button-background-activated);
  --ripple-color: var(--option-button-ripple-color);
  /* --box-shadow:  0px 8px 14px var(--option-button-box-shadow-color); */
}

.app-screen-title {
  --background: none;
  font-family: 'Porsche Next TT';
  font-style: normal;
  font-weight: 300;
  font-size: 3rem;
  line-height: inherit;
  text-align: center;
  letter-spacing: -0.01em;
}

.background-back-whimsical,
.background-back-modern,
.background-back-dark {
  position: absolute;
  margin-top: 180px;
  height: calc(100vh - 180px);
  width: 100%;
  background-color: var(--greetly-background-color);
  z-index: 0;
}

.background-back-modern {
  border-radius: 30px 30px 0px 0px;
}

@media screen and (max-width: 767px) {
  .background-back-whimsical,
  .background-back-modern,
  .background-back-dark {
    background-color: var(--greetly-background-color);
    background: linear-gradient(transparent 85%, white);
    border-bottom: none;
  }
}

.brand-logo-new-design {
  margin-bottom: 10px;
  height: 100%;
  object-fit: scale-down;
  z-index: 1;
}

.img-container-new-design {
  width: 25%;
  height: 15%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  object-fit: fill;
  z-index: 1;
  min-height: 150px;
}

.app-screen-title-new-design {
  --background: none;
  font-family: 'Porsche Next TT';
  font-style: normal;
  font-weight: 300;
  font-size: 4vw;
  line-height: inherit;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  color: var(--text-color);
}

.app-screen-title-new-design.modern.button-page,
.app-screen-title-new-design.dark.button-page,
.app-screen-title-new-design.whimsical.button-page {
  color: var(--button-header-text-color);
}

@media (orientation: landscape) and (max-width: 1180px) {
  .app-screen-title-new-design.dark {
    margin-top: 48px;
    margin-bottom: 2px;
  }

  .app-screen-title-new-design.modern {
    margin-top: 45px;
    margin-bottom: 5px;
  }

  .app-screen-title-new-design.whimsical {
    margin-top: 40px;
  }
}

@media (orientation: landscape) and (min-width: 1180px) {
  .app-screen-title-new-design.whimsical {
    margin-top: 45px;
  }

  .app-screen-title-new-design.dark {
    margin-top: 48px;
    margin-bottom: 2px;
  }

  .app-screen-title-new-design.modern {
    margin-top: 45px;
    margin-bottom: 5px;
  }
}

.app-screen-title-width {
  --background: none;
  font-family: 'Porsche Next TT';
  font-style: normal;
  font-weight: 300;
  font-size: 5.5vw;
  line-height: inherit;
  text-align: center;
  letter-spacing: -0.01em;
}

.generic-button {
  text-transform: capitalize;
  font-weight: bold;
  height: 55px;
  width: 235px;
  --padding-start: 20px;
  --padding-end: 20px;
  --border-radius: 30px;
  font-size: 25px;
  display: block;
  margin: 20px auto 20px auto !important;
  --background: var(--button-button-color);
  --background-hover: var(--basic-btn-hover-color);
  --background-focused: var(--basic-btn-focused-color);
  --background-activated: var(--basic-btn-activated-color);
  --ripple-color: var(--basic-btn-ripple-color);
  color: var(--button-text-color);
}

.generic-button-cancel {
  text-transform: capitalize;
  font-weight: bold;
  height: 55px;
  width: 235px;
  --padding-start: 20px;
  --padding-end: 20px;
  --border-radius: 30px;
  font-size: 25px;
  display: block;
  margin: 20px auto 20px auto !important;
  --background: var(--basic-btn-color-cancel);
  --background-hover: var(--basic-btn-hover-color-cancel);
  --background-focused: var(--basic-btn-focused-color-cancel);
  --background-activated: var(--basic-btn-activated-color-cancel);
  --ripple-color: var(--basic-btn-ripple-color);
  color: var(--button-text-color);
}

.opacity-zero {
  opacity: 0;
}
@media screen and (max-width: 600px) {
  .generic-button {
    max-width: 150px;
  }

  .generic-button-cancel {
    display: block;
    margin: 0 auto;
    width: 15vw;
    height: 15vw;
    font-size: 7vw;
    --padding-bottom: 2px;
    /* --padding-start: 1.5px; */
    --background: var(--background-generic-button-cancel);
    --background-hover: var(--option-button-background-hover-cancel);
    --background-focused: var(--option-button-background-focused-cancel);
    --background-activated: var(--option-button-background-activated-cancel);
    --ripple-color: var(--option-button-ripple-color);
    --border-radius: 50%;
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--common-gray);
    --box-shadow: 0px 8px 14px var(--option-button-box-shadow-color);
    --color: var(black);
    /* --padding-end: 0; */
  }
}

.color-button {
  --color: var(--ion-color-primary-contrast);
  --border-color: var(--ion-color-primaryr);
  --background: var(--ion-color-primary);
  --background-hover: var(--option-button-background-hover);
  --background-focused: var(--option-button-background-focused);
  --background-activated: var(--option-button-background-activated);
  --ripple-color: var(--option-button-ripple-color);
  /* --box-shadow:  0px 8px 14px var(--option-button-box-shadow-color); */
}

.cancel-button {
  position: absolute;
  left: 50px;
  top: 50px;
  --border: 2px solid #8d8d8d;
  --background: none;
  --border-radius: 30px;
  color: #8d8d8d;
  border: 2px solid #8d8d8d;
  border-radius: 30px;
  --background-hover: var(--option-button-background-hover);
  --background-focused: var(--option-button-background-focused);
  --background-activated: var(--option-button-background-activated);
  --ripple-color: var(--option-button-ripple-color);
  /* width: 45px;
    height: 45px; */
  width: 60px;
  height: 60px;
  font-size: 25px;
  --padding-start: 0;
  --padding-end: 0;
}
.cancel-button .options-icon.fa-home {
  font-size: 35px;
}

.web-language-button {
  position: absolute;
  right: 20px;
  top: 30px;
  --border: 2px solid #8d8d8d;
  --background: none;
  --border-radius: 30px;
  color: #8d8d8d;
  border: 2px solid #8d8d8d;
  border-radius: 30px;
  --background-hover: var(--option-button-background-hover);
  --background-focused: var(--option-button-background-focused);
  --background-activated: var(--option-button-background-activated);
  --ripple-color: var(--option-button-ripple-color);
  width: 60px;
  height: 60px;
  font-size: 25px;
  --padding-start: 0;
  --padding-end: 0;
}
.web-language-button .options-icon.fa-bars {
  font-size: 25px;
}

.options-icon.fa-angle-double-right {
  font-size: 25px;
}

.options-icon.fa-spinner {
  position: relative;
  width: 25px;
  height: 25px;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.options-icon.fa-spinner:before {
  content: '\f110';
  font-family: FontAwesome;
  font-size: 25px;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 600px) {
  .brand-logo {
    margin: 5vw auto;
    height: 40vw;
    width: 40vw;
  }

  .copyright {
    bottom: 0;
    opacity: 0.5;
  }
}

/* allows camera preview to be behind the webview */
html,
body,
.ion-app,
.ion-content {
  /* background-color: transparent; */
  overflow: auto;
}

/*TABLET widescreen 16/9, actually everything less than 3/2*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2) {
  .cancel-button {
    left: 3vh;
    top: 3vh;
    --border: 1px solid #8d8d8d;
    --border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 25px;
  }

  .img-container-new-design {
    min-height: 0px;
  }

  .app-screen-title-new-design.modern,
  .app-screen-title-new-design.dark,
  .app-screen-title-new-design.whimsical {
    margin-top: 45px;
  }
}

@media screen and (max-width: 400px) {
  .generic-button {
    max-width: 120px;
  }
}
