/*TABLET LANDSCAPE, DESKTOP & BEYOND*/

.workflows {
  ion-content {
    width: auto;
    --background: var(--workflows-page-background);
    z-index: -2;
  }
  .title {
    overflow: hidden;
    height: 10%;
    max-height: 120px;
    ion-label {
      white-space: unset;
    }
  }
  .image {
    margin-top: 180px;
    height: 15%;
    --background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .workflow-wrapper {
    height: calc(100vh - 13% - 190px);
    padding: 0 5px;
  }

  .container {
    width: 98%;
    max-width: 1000px;
    height: calc(100vh - 350px);
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 999999;
  }
  .container-new-design {
    width: 98%;
    height: calc(100vh - 350px);
    margin: 0 auto;
    min-height: 230px;
    max-width: 1100px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 999999;
  }
  .copyright {
    display: block;
    text-align: center;
    width: 100%;
    --min-height: 20px;
    position: relative;
    bottom: 45px;
  }
  .language {
    position: relative;
    bottom: 50px;
    margin-left: 5vw;
    width: 66px;
    height: 66px;
    --min-height: 66px;
    --border-radius: 50%;
    --border-style: solid;
    --border-color: gray;
    --border-width: 1px;
    --padding-start: 13px;
    --padding-end: 13px;
    --background: white;
    --background-activated: lightgray;
    --background-focused: lightgray;
    --background-hover: lightgray;
    --box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.05);
    ion-icon {
      width: 50px;
      height: 50px;
    }
  }
  .hidden-menu-button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    font-size: 25px;
    --padding-start: 0;
    --padding-end: 0;
  }
  .image-workflows {
    background-image: var(--workflows-background-image-portrait);
    position: absolute;
    z-index: 0;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
  }
  .workflow-welcome-title {
    position: absolute;
    top: 54px;
    width: 100%;
    height: 60px;
    --background: none;
    font-family: 'Porsche Next TT bold';
    font-style: normal;
    font-weight: 300;
    font-size: 4vw;
    text-align: left;
    letter-spacing: -0.01em;
    color: var(--text-color);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand-logo-new-design {
    margin-bottom: 10px;
    height: 100%;
    object-fit: scale-down;
    z-index: 1;
  }

  .background-back-dark,
  .background-back-modern,
  .background-back-whimsical {
    z-index: 2;
    background-color: var(--button-background-color);
  }

  .app-screen-title-new-design {
    &.modern.button-page,
    &.dark.button-page,
    &.whimsical.button-page {
      color: var(--button-header-text-color);
      z-index: 2;
    }
  }
}

/*TABLET Portrait*/
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .workflows .container {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

/*PHONE*/
@media screen and (max-width: 767px) {
  /*use vw*/
  .workflows {
    ion-content {
      width: auto;
    }
    .title {
      font-size: 6.7vw;
      line-height: inherit;
    }
    .container {
      width: 100vw;
      height: auto;
      flex-flow: column;
      max-height: unset;
      height: calc(100vh - 350px);
    }
    .container-new-design {
      width: 100vw;
      height: calc(100vh - 270px);
      flex-flow: column;
      max-height: unset;
    }
    .language {
      bottom: 0;
      margin-left: 5vw;
      width: 18vw;
      height: 18vw;
      --padding-start: 3.5vw;
      --padding-end: 3.5vw;
      ion-icon {
        width: 50px;
        height: 50px;
      }
    }
    .copyright {
      bottom: 0;
    }
  }
}

@media screen and (max-width: 1600px) {
  .workflows .container-new-design {
    height: calc(100vh - 270px);
  }
}

@media (orientation: landscape) {
  .workflows {
    .image-workflows {
      background-image: var(--workflows-background-image-landscape);
    }
    .workflow-welcome-title {
      font-size: 2.5vw;
    }
    .container {
      height: 55%;
    }
  }
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2) {
  .workflows .workflow-welcome-title {
    top: 3vh;
    transform: translateY(4px);
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) {
  .workflows {
    .container {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .container-new-design {
      height: 100%;
    }

    // .workflow-wrapper {
    //   height: auto;
    // }
  }
}
