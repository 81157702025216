.select {
  max-width: 1000px;
  width: 85%;
  margin: 10px auto 35px auto;
  --background: none;
  z-index: 0;
  --highlight-color-focused: var(--input-active-color);
  --border-width: 0 0 3px 0;
  --border-color: var(--input-element-color);
  --background-focused: none;
  font-size: 32px;
}

.select .name {
  color: var(--input-element-filled-color);
  white-space: unset;
}

.select .value {
  color: var(--text-color);
}

ion-alert .alert-radio-label {
  white-space: unset !important;
}

ion-alert .alert-radio-button {
  display: contents !important;
}
.select.warning {
  color: red !important;
  --border-color: red;
}
.select.warning .name {
  color: red;
}

.select.warning .value {
  color: red;
}

.item-interactive-disabled.item-select.item-select-disabled ion-label,
.select-disabled {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .select {
    margin: 6vw auto;
    font-size: 5vw;
  }

  .select .name {
    /* color: var(--input-element-color); */
    white-space: unset;
  }

  .select .value {
    color: var(--text-color);
  }
}
