.no-params ion-content{
    --background: radial-gradient(closest-side, #ffffff 90%,  #000000 500%);
}

.no-params .greetly-logo {
    max-height: 100px;
    margin: 100px auto;
    width: fit-content;
}

.no-params .find-interface {
    display: block;
    margin: 0 auto;
    width: 80vw;
    max-width: 1300px;
    font-size: 50px;
    text-align: center;
    line-height: initial;
}

.no-params .copyright {
    position: absolute;
    bottom: 0;
}

.no-params .retryItem {
    --background: none;
    background: none;
}

.no-params .title {
    color: #8d8d8d;
    font-size: 30px;
    text-align: center;

    display: block;
    margin: 100px auto;
    width: 80vw;
    max-width: 1300px;
    line-height: initial;
}

.no-params .retry-button {
    display: flex;
    margin: 7vw auto;
    width: 30vw;
    height: 7vw;
    /* --background: var(--background-next-button); */
    --background-hover: var(--option-button-background-hover);
    --background-focused: var(--option-button-background-focused);
    --background-activated: var(--option-button-background-activated);
    --ripple-color: var(--option-button-ripple-color);
    /* --border-radius: 50%; */
    --border-radius: 25vw;
    --box-shadow: 0px 8px 14px var(--option-button-box-shadow-color);
    font-size: 30px;
    --background: #f7941e;
    --color: #ffffff;
}

/*mobile*/
@media screen and (max-width: 600px) {
    .no-params .greetly-logo {
        height: 20vw;
        margin: 20vw auto;
    }
    .no-params .find-interface {
        display: block;
        margin: 0 auto;
        width: 80vw;
        font-size: 7vw;
        text-align: center;
        line-height: 7vw;
    }
    .no-params .copyright {
        position: absolute;
        bottom: 0;
    }
}

/*tablet portrait*/
@media screen and (min-width: 601px) and (max-width: 900px) {
    .no-params .greetly-logo {
        height: 20vw;
        margin: 20vw auto;
    }
    .no-params .find-interface {
        display: block;
        margin: 0 auto;
        width: 80vw;
        font-size: 7vw;
        text-align: center;
        line-height: 7vw;
    }
    .no-params .copyright {
        position: absolute;
        bottom: 0;
    }
}

/*tablet landscape*/
@media screen and (min-width: 901px) and (max-width: 1200px) {
    .no-params .greetly-logo {
        height: 20vw;
        margin: 13vw auto;
    }
    .no-params .find-interface {
        display: block;
        margin: 0 auto;
        width: 80vw;
        font-size: 5vw;
        text-align: center;
        line-height: 7vw;
    }
    .no-params .copyright {
        position: absolute;
        bottom: 0;
    }

    .no-params .retryItem {
        --background: none;
        background: none;
    }

}