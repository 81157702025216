
@font-face {
    font-family: 'Roboto Condensed R';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/RobotoCondensed-Regular.ttf');
  }
 .badge *{
    font-family: 'Roboto Condensed R';
  }

.badge {
    position: relative;
    color: black;
    background: white;
    height:372px;
    width:600px;
    display: flex;
    flex-wrap: wrap;
}
.badge .logo {
    /* border: solid 1px black; */
    width: 100px;
    height: 100px;
    object-fit: contain;
    position: absolute;
    left: 480px;
    top: 16px;

}

.badge .photo-container {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 50px 16px 0 16px;
}

.badge .photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.photo-custom-badge {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.badge .information {
    width: 340px;
    padding-top: 50px;
}

.badge .names {
    font-weight: bold;
    font-size: 36px;
}

.badge .company {
    margin-top: 10px;
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.badge .host {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.badge .date {
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.badge .text {
    display: block;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    margin: 0 auto;
    font-weight: bold;
    font-size: 36px;
    width: 100%;
    text-align: center;
}
