.url_field {
    width: 85%;
    display: block;
    max-width: 1000px;
    margin: 60px auto;
    --color: var(--input-element-color);
    --background: none;
    z-index: 0;
    --highlight-color-focused: var(--input-active-color);
    --border-width: 0 0 3px 0;
    --border-color: var(--input-element-color);
    --background-focused: none;
    font-size: 35px;
}

.url_field .name {
    --color: var(--input-element-url-color);
    white-space: unset !important;
    color: var(--input-element-url-color);
    z-index: 11;
    display: block;
    font-size: 31.25px !important;
    padding-top: 10px;
    text-transform: capitalize;
    text-decoration: underline;
}



@media screen and (max-width: 767px) {
    .url {
        margin: 6vw auto;
    }
    
    .url .name {
        --color: var(--input-element-url-color);
        font-size: calc(4vw * 1.25) !important;
    }
}