.timepicker {
  width: 85%;
  max-width: 1000px;
  margin: 40px auto;
  font-size: 18px;
  --color: var(--input-element-filled-color);
  --background: none;
  z-index: 0;
  --highlight-color-focused: var(--input-active-color);
  --border-width: 0 0 3px 0;
  --border-color: var(--input-element-color);
  --background-focused: none;
  font-size: 35px;
  margin: 10px auto;
  height: 70px;
  margin-bottom: 40px;
  --inner-border-width: 0;
}

.timepicker .item-inner {
  border: none;
}

.timepicker .name {
  --color: var(--input-element-filled-color);
  white-space: unset !important;
  color: var(--input-element-filled-color);
  z-index: 11;
  display: block;
  font-size: 31.25px !important;
  text-transform: capitalize;
}

.timepicker ion-label {
  white-space: unset !important;
  color: var(--input-element-color);
  z-index: 11;
  display: block;
  font-size: 31.25px !important;
  padding-top: 10px;
  text-transform: capitalize;
  pointer-events: none;
}

.timepicker.warning {
  color: red;
  --border-color: red;
}
.timepicker.warning ion-checkbox {
  --border-color: red;
}

.time-btn,
.time-label {
  font-size: 1.3rem;
  margin: 5px 0;
}

@media screen and (max-width: 767px) {
  .timepicker {
    margin: 6vw auto;
    border-bottom: 1px solid var(--input-element-color);
    font-size: 5vw;
  }

  .timepicker ion-label {
    white-space: unset !important;
    color: var(--input-element-color);
    z-index: 11;
    display: block;
    font-size: calc(4vw * 1.25) !important;
    padding-top: 10px;
    text-transform: capitalize;
  }
}
