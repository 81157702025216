/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@font-face {
  font-family: 'Porsche Next TT';
  src: url('../assets/fonts/PorscheNextTT-Regular_0.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Porsche Next TT thin';
  src: url('../assets/fonts/PorscheNextTT-Thin_0.ttf');
  font-weight: 300;
  font-style: normal;
}

* {
  font-family: 'Roboto Condensed', sans-serif;
}

/** Ionic CSS Variables **/
:root {
  touch-action: manipulation;
  /*--ion-font-family: 'Porsche Next TT thin', 'Porsche Next TT' ;*/
  --ion-font-family: 'Roboto Condensed', sans-serif;

  /** primary **/
  /* --ion-color-primary: #d5001c; */
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  /* --ion-color-primary-shade: #ac0017; */
  /* --ion-color-primary-tint: #d1243b; */

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;  

  /*defaults for any new greetly app ^^^*/
  
  /*can be overwritten at start in app main file*/

  --greetly-background-color: white;
  --ion-background-color: var(--greetly-background-color);

  --text-color: black;
  --color-icon: black;
  --color-header-text: black;

  --workflows-page-background: white;
  --greetly-background-color: none;

  --input-element-filled-color: black; 
  --input-element-color: #bbbbbb; 
  --input-active-color: var(--color-icon);
  --input-focus-color: black;

  --input-element-url-color: #0645AD; 

  --common-gray: #bbbbbb;

  --user-card-img-border-color: var(--input-element-color);
/* white button */
  --option-button-background: linear-gradient(212.94deg, #FFFFFF 46.8%, #F5F5F5 100%);
  --option-button-background-hover: rgba(133, 133, 133, 0.205);
  --option-button-background-focused: rgba(133, 133, 133, 0.205);
  --option-button-background-activated: rgba(133, 133, 133, 0.205);
  --option-button-ripple-color: rgba(133, 133, 133, 0.205);

  --option-button-border-color: #b6b6b6;
  --option-button-box-shadow-color: rgba(0, 0, 0, 0.1);

  /* primary color button*/
  --basic-btn-color: var(--color-icon);
  --basic-btn-color-transparent: var(--color-icon-transparent);
  --basic-btn-activated-color: #0000002c;
  --basic-btn-focused-color: #00000044;
  --basic-btn-hover-color: #00000044;
  --basic-btn-ripple-color: #00000044;

  /* secondary color button*/
  --basic-btn-color-cancel: var(--input-element-color);
  --basic-btn-activated-color-cancel: #0000002c;
  --basic-btn-focused-color-cancel: #00000044;
  --basic-btn-hover-color-cancel: #00000044;
  --basic-btn-ripple-color-cancel: #00000044;


  --background-generic-button: linear-gradient(241.4deg, #FFFFFF 16.67%, #F8F8F8 85.42%);

  --copyright-text-color: var(--text-color);
  --darker-gray: #575757;

  --image-container-margin-top-landscape: 4vh;

  --welcome-background-image-portrait: url("../assets/img/bg_portrait.png");
  --welcome-background-image-landscape: url("../assets/img/bg_landscape.png");
  --workflows-background-image-portrait: url("../assets/img/bg_portrait.png");
  --workflows-background-image-landscape: url("../assets/img/bg_landscape.png");

  --banner-color-background: transparent;

  --welcome-button-color: var(--basic-btn-color);
  --welcome-button-text-color: white;
  --welcome-accent-color: var(--greetly-background-color);

  --button-header-text-color: var(--text-color);
  --button-background-color: var(--greetly-background-color);
  --button-button-color: var(--basic-btn-color);
  --button-text-color: var(--text-color);
  --button-icon-color: var(--color-icon);
  --button-icon-color-transparent:  var(--color-icon-transparent);
}

.black-loading-class {
  --background: #222;
  --spinner-color: #fff;

  color: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* background-color: #ffffff; */
  }
}
