.workflow-item.old-theme {
  &.bottom {
    overflow: hidden;
    padding: 0;
    margin: 5px;
  }

  .workflow-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: linear-gradient(220deg, #3c3b3b 40%, var(--basic-btn-color) 100%);
    padding: 5px;
  }

  .icon-shadow {
    top: 25px;
    right: 20px;

    .options-icon {
      font-size: 7rem;
    }
  }
}

.label-wrapper {
  max-height: 100%;
  padding-bottom: 15px;
  padding-left: 15px;
}

.label-wrapper .options-icon {
  font-size: 3rem;
}

.workflow-item.old-theme .workflow-label {
  --color: #fff;
  color: #fff;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
  width: 100%;
}

.workflow-item .options-icon {
  margin: 10px;
}

@media screen and (max-width: 767px) {
  .workflow-item {
    &.old-theme {
      &.bottom {
        overflow: initial;
      }

      .workflow-button {
        height: 150px;
        align-items: center;
      }

      .options-icon {
        font-size: 3rem;
        padding: 10px;
      }

      .icon-shadow {
        top: 25px;
        right: 20px;

        .options-icon {
          font-size: rem;
        }
      }
    }
  }
}
