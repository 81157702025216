.user-card {
    display: flex;
    flex-direction: column;
    width: 221px;
    min-height: 290px;
    height: auto;
    flex: 0 0 auto;
    margin: 20px 10px;
    border-radius: 20px;
    /* border: solid 0px var(--common-gray); */
    border: solid 2px var(--common-gray);
    box-shadow: 0px 8px 14px var(--option-button-box-shadow-color);
    --padding-start: 0;
    --padding-end: 0;
    --background: none;
    --border-radius: 20px;
    --box-shadow: 0;
    --background-hover: var(--option-button-background-hover);
    --ripple-color: var(--option-button-ripple-color);
    --background-focused: var(--option-button-background-focused);
    --background-activated: var(--option-button-background-activated);
    z-index: 1;
}
@media (orientation: landscape) {
    .user-card {
        min-height: 40%;
    }
    .deco-btn {
        margin-bottom: 20px;
    }
    .user-cont .user-img{
        margin: 15px auto !important;
    }
    
}

.user-cont {
    /* width: 85%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deco-btn {
    background-color: var(--button-button-color);
    border-radius: 20px;
    width: 150px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: var(--button-text-color);
}

.user-cont .user-img {
    border: solid 2px var(--user-card-img-border-color);
    margin: 25px auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /*  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.user-cont .user-img .initials {
    font-size: 39px;
    letter-spacing: 2px;
}

.user-cont .info-container{
    white-space: initial;
}

.user-cont .card-button {
    font-size: 13px;
    font-weight: bold;
    display: block;
    width: 120px;
    --border-radius: 20px;
    margin: 50px auto 0;
    text-transform: capitalize;
}

.user-cont .icon {
    display: block;
    width: 100%;
    text-align: center;
    margin: 2vw 0;
    color: var(--common-gray);
}

.user-cont .name {
    color: var(--color-header-text);
    display: block;
    width: 100%;
    font-size: 21px;
    text-align: center;
    line-height: initial;
}

.user-cont .position {
    color: var(--common-gray);
    display: block;
    width: 100%;
    /* font-size: 12px; */
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 6px;
}

.user-cont .arrow-btn {
    display: none !important;
}
.user-cont .tablet-btn {
    display: flex !important;
}

@media screen and (max-width: 600px) {

    .user-cont .arrow-btn {
        display: block !important;
    }
    .user-cont .tablet-btn{
        display: none !important;
    }
    .user-card {
        flex-direction: row;
        width: auto;
        min-height: 10vw;
        margin: 3vw 1vw;
        border-radius: 0;
        --border-radius: 0;
        border: solid 0px var(--common-gray);
        box-shadow: unset;
    }
    .user-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .user-cont .user-img {
        margin: 0;
        width: 15vw;
        height: 15vw;
    }

    .user-cont .info-container {
        margin: 0 auto 0;
        width: 60vw;
        padding-left: 10px;
    }
    
    .user-cont .card-button {
        font-size: 3.5vw;
        width: 30vw;
        --border-radius: 5vw;
        margin: 4vw auto 5vw;
    }

    .user-cont .icon {
        display: block;
        width: fit-content;
        padding: 0 2vw;
        color: var(--ion-color-primary);
    }
    
    .user-cont .name {
        text-transform: capitalize;
        font-size: 5.5vw;
        line-height: 7vw;
        white-space: normal;
        text-align: left;

    }
    
    .user-cont .position {
        font-size: 3.5vw;
        white-space: normal;
        text-align: left;
    }
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){
    .user-card {
        width: 33vh;
        min-height: 15%;
        max-height: 15%;
        margin: 3vh 1.5vh;
        border-radius: 3vh;
    }

    .user-cont .user-img {
        border: solid 2px var(--user-card-img-border-color);
        margin: 2vh auto !important;
        width: 13vh;
        height: 13vh;
        border-radius: 50%;
    }

    .user-cont .user-img .initials {
        font-size: 6vh;
        letter-spacing: 0.3vh;
    }

    .user-cont .name {
        width: 100%;
        font-size: 3vh;
    }
    
    .user-cont .position {
        width: 100%;
        font-size: 2vh;
        margin-top: 1vh;
    }

    .deco-btn {
        border-radius: 10vh;
        width: 23vh;
        height: 6.5vh;
        margin-top: 2vh;
    }
}