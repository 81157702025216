.signatureEmbedded {
    max-width: 1000px;
    width: 85%;
    margin: auto
}

.signatureEmbedded .signature-text {
    grid-column: 1;
    margin: auto;
    display: block;
    width: fit-content;
    font-weight: normal;
    font-size: 35px;
}

.signatureEmbedded .signature-box {
    width: 95vw;
    height: 35vw;
    /* background: repeating-linear-gradient( 45deg, #ffffff, #ffffff 20px, #fbfbfb 20px, #fbfbfb 40px ); */
    margin: 0 auto;
    border-bottom: solid 1px var(--common-gray);
}
.signatureEmbedded-below {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
}

.signatureEmbedded .signature-below ion-label {
    margin: 0;
    font-weight: bold;
    text-align: left;
    font-size: 5vw;
}

.signatureEmbedded .email-copy {
    width: fit-content;
    margin: 10vw auto;
    text-align: center;
    display: block;
    color: var(--common-gray);
}

.signatureEmbedded .fullName {
    white-space: normal;
}

.signatureEmbedded .signaturebox-header {
    width: 98vw;
    margin: auto;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
}

.signatureEmbedded .sign-canvas {
    border-bottom: 1px solid black;
    touch-action: none;
}

.signatureEmbedded .clearbtn {
    text-transform: capitalize;
    grid-column: 3;
    width: fit-content;
    /* margin-left: auto; */
    --border-radius: 30px;
    --border-width: 1px;
    --border-style: solid;
    margin: auto;
}
.signatureEmbedded .signature-above {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 33% 34% 33%;
    text-align: center;
    font-weight: bold;
}

.signatureEmbedded .signature-above .fullName {
    font-size: 20px;
}

.signatureEmbedded .signature-above .clearbtn {
    font-size: 20px;
    --border-radius: 0;
    --border-width: 0;
    --border-style: none;
    color: var(--ion-color-primary);
    --box-shadow: none;
}

.signatureEmbedded .ndaError {
    text-align: center;
    margin: 20px 0;
    color: red;
    font-size: 18px;
}


@media screen and (max-width: 600px) {
/*use vw*/
    .signatureEmbedded ion-content{
        width: auto;
    }

    .signatureEmbedded .title {
        font-size: 6.7vw;
        line-height: inherit;
    }

    .signatureEmbedded .title ion-label {
        white-space: unset;
    }

    .signatureEmbedded .NDA-text {
        margin: 0 4vw;
    }

    .signatureEmbedded .signature-box {
        width: 95vw;
        height: 35vw;
    }

    .signatureEmbedded .signature-text {
        margin: 10vw auto 5vw;
    }

    /* TODO */
    /* .signature .signature-above {
        --min-height: 0;
        margin-top: 5vw;
        --padding-start: 10px;
    }
    .signature .signature-above ion-label{
        margin: 0;
        color: var(--darker-gray);
    }
    .signature .signature-above .date{
        text-align: right;
    } */

    .signatureEmbedded .signature-below ion-label {
        text-align: left;
        font-size: 5vw;
    }

    .signatureEmbedded .email-copy {
        margin: 10vw auto;
    }


    .signatureEmbedded .fullName {
        white-space: normal;
    }

    .signatureEmbedded .signaturebox-header {
        width: 98vw;
        margin: auto;
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
    }

    .signatureEmbedded .sign-canvas {
        /* background: aliceblue; */
        border-radius: 0;
        margin:  0 15px;
    }

    .signatureEmbedded .signature-below{
        display: flex;
        margin: 0 2.41vw;
        justify-content: space-around;
        margin-bottom: 40px;
    }
}
