.checkout ion-content{
    width: auto;
    --background: var(--greetly-background-color);
}

.checkout .visitor-list-container {
    width: 90vw;
    flex-direction: column;
    margin: 0 auto;
}
.checkout .visitor-section {
    flex-direction: row;
    width: auto;
    min-height: 10vw;
    margin: 3vw 1vw;
    border-radius: 0;
    --border-radius: 0;
    /* border: solid 0px var(--common-gray); */
    border-bottom: solid 1px var(--common-gray);
    display: flex;
    height: auto;
    flex: 0 0 auto;
    --padding-start: 0;
    --padding-end: 0;
    --background: none;
    --box-shadow: 0;
    --background-hover: var(--option-button-background-hover);
    --ripple-color: var(--option-button-ripple-color);
    --background-focused: var(--option-button-background-focused);
    --background-activated: var(--option-button-background-activated);
    /*  */
    color: var(--color-header-text);
    display: block;
    width: 100%;
}
.visitor-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.visitor-cont .info-container {
    margin: 0 auto 0;
    width: 85vw;
    /* padding-left: 2vw; */
    display: flex;
    flex-direction: row;
}
.visitor-cont .name {
    text-transform: capitalize;
    font-size: 4vw;
    line-height: 7vw;
    white-space: normal;
    text-align: left;
}
.visitor-cont .icon {
    display: block;
    width: fit-content;
    /* padding: 0 2vw; */
    color: var(--ion-color-primary);
    /* margin-right: 3vw; */
    font-size: 21px;
}


@media screen and (max-width: 600px) {
    /*use vw*/
        .checkout ion-content{
            width: auto;
        }
    
        .checkout .title {
            font-size: 6.7vw;
            line-height: inherit;
        }
        
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){

    .checkout .title {
        font-size: 6vh;
        line-height: 12.1vh;
    }
}