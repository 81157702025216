.language-button {
  position: absolute;
  right: 50px;
  top: 50px;
  --border: 2px solid #8d8d8d;
  --background: none;
  --border-radius: 15px;
  --background-hover: var(--option-button-background-hover);
  --background-focused: var(--option-button-background-focused);
  --background-activated: var(--option-button-background-activated);
  --ripple-color: var(--option-button-ripple-color);
  width: 60px;
  height: 60px;
  font-size: 25px;
  --padding-start: 0;
  --padding-end: 0;
  --background: transparent;
}

.home-button {
  position: absolute;
  left: 50px;
  top: 50px;
  --border: 2px solid #8d8d8d;
  --background: none;
  --border-radius: 15px;
  --background-hover: var(--option-button-background-hover);
  --background-focused: var(--option-button-background-focused);
  --background-activated: var(--option-button-background-activated);
  --ripple-color: var(--option-button-ripple-color);
  width: 60px;
  height: 60px;
  font-size: 25px;
  --padding-start: 0;
  --padding-end: 0;
  --background: transparent;
}

.home-button.whimsical,
.language-button.whimsical {
  --border-radius: 50%;
}

.home-button .options-icon.fa-home,
.language-button .options-icon.fa-language {
  display: table-cell;
  font-size: 32px;
  vertical-align: middle;
  transform: translateY(-2px);
}

.home-button .shape-button-back,
.language-button .shape-button-back {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 60px;
  width: 60px;
  border-radius: 15px;
}

.home-button .shape-button-back.modern,
.language-button .shape-button-back.modern {
  background: var(--button-icon-color-transparent);
}

.home-button .shape-button-back.dark,
.language-button .shape-button-back.dark {
  background: var(--button-button-color);

  border: 1px solid #5d5f6b;
  box-sizing: border-box;

  color: var(--button-icon-color-transparent);
}

.home-button .shape-button-back.whimsical,
.language-button .shape-button-back.whimsical {
  background-image: linear-gradient(127.82deg, var(--button-icon-color) 30.82%, var(--color-icon-shade) 101.86%);
  border-radius: 50%;
}

.language-button .shape-button-back.whimsical.active {
  background-image: linear-gradient(127.82deg, #f7941e 30.82%, var(--color-icon-shade) 101.86%);
}

.home-button .shape-button,
.language-button .shape-button {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  text-align: center;

  height: 48px;
  width: 48px;
  border-radius: 15px;

  background: var(--button-icon-color);
  display: table;
}

.home-button .shape-button.dark,
.language-button .shape-button.dark {
  background: transparent;
  color: var(--button-icon-color);
}

.home-button .shape-button.whimsical,
.language-button .shape-button.whimsical {
  background: transparent;
}

.whimsical-wave {
  width: 100%;
  height: 50px;
  margin-top: 140px;
  position: absolute;
  z-index: 2;
  transform: rotate(180deg) translateY(10px);
  background-color: transparent;
}

.label-new-line {
  white-space: pre-line !important;
}

@media screen and (max-width: 600px) {
  .language-button {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .home-button {
    position: absolute;
    left: 20px;
    top: 20px;
  }
}

.language-button {
  font-size: 1.9rem;
}

.language-button .shape-button {
  padding: 6px;
  padding-left: 7px;
  text-shadow: 1px 1px 1px gray;
}

.language-button .shape-button.active {
  background-color: #f7941e;
}
