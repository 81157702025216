.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--greetly-background-color);
}
.welcome .image-welcome {
    background-image: var(--welcome-background-image-portrait);
    /* background-color: pink; */
    position: absolute;
    z-index: -1000;
    object-fit: fill;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
}
.welcome video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
}

/* .welcome .video-block video { //for top video block instead of image behind logo
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 50%;
    z-index: -100;
    transform: translateX(0%) translateY(-30.5%);
} */

.welcome .container {
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.welcome .container .top-quarter {
    height: 39.5%;
    width: 90%;
    /* height: 42%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
}
.welcome .container .mid-quarter {
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.welcome .container .mid-quarter.newtheme {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.welcome-img-container {
    width: 50%;
    height: 76%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 3vw;
    object-fit: fill;
}

.welcome-img-container.newtheme {
    width: 100%;
    height: 100%;
}

.welcome .welcome-img-container .welcome-image {
    width: auto;
    height: 100%;
    background-color: transparent;
    object-fit: contain;
}

.welcome .container .title {
    color: #8d8d8d;
    font-size: 45px;
    font-weight: bold;
    margin-top: 1.3vh;
    text-align: center;
    padding: 0 45px;
}

.welcome .container .thermal-waiting-title {
    color: #8d8d8d;
    font-size: 30px;
    font-weight: bold;
    margin-top: 1.3vh;
}

.welcome .camera-prev,
.camera-prev {
    background: #000000;
    /* position: absolute;
    left: 100px;
    top: 0px; */
    width: 200px;
    height: 200px;
    z-index: 255;
    /* margin-bottom: 10px; */
}
.welcome .vertical-bar,
.vertical-bar {
    width: 2px;
    /* background-color: #6a6969; */
    background-color: #8d8d8d;
    height: 35%;
}
.welcome .or,
.or {
    /* color: #6a6969; */
    color: #8d8d8d;
    padding: 10px;
    font-weight: bold;
}
.welcome .mid-bar,
.mid-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: center; */
    align-items: center;
    margin: 0 0 55px 0;
}

.welcome .mid-bar.newtheme,
.mid-bar.newtheme {
    width: 170px;
}

.bottom-footer {
    display: flex;
    justify-content: center;
}

.welcome .footer-w, .footer-w {
    height: 38%;
    display: block;
    width: 75%;
}

.welcome .footer-w.newtheme, 
.footer-w.newtheme {
    padding-top: 5%;
    height: 32%;
    width: 100%;
    background-color: var(--welcome-accent-color);
}

.welcome .footer-w.newtheme.modern, 
.footer-w.newtheme.modern {
    border-radius: 30px 30px 0px 0px;
}

.welcome .container .whimsical-wave, 
.container .whimsical-wave {
    width: 100%;
    height: 50px;
    position: relative;
    margin-top: 20px;
    z-index: 2;
    transform: rotate(180deg) translateY(-20px);
}

.single-qr {
    margin: auto;
}

.welcome .no-touch-footer, .no-touch-footer {
    /* width: 100%;
    --ion-text-align: "center";
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 33%; */
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome .no-touch-text,
.no-touch-text {
    font-size: 30px;
    font-family: 'Roboto Condensed';
    /* font-weight: 700; */
    /* color: var(--ion-color-primary); */
    /* color: #6a6969; */
    color: #8d8d8d;
    font-weight: bold;
}
.welcome .no-touch-cont,
.no-touch-cont {
    padding-bottom: 35px;
    text-align: center;
}
.welcome .margin-qr {
    margin-left: 27px;
}
.welcome .scan-cont,
.scan-cont,
.welcome .prev-cont,
.prev-cont {
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-content: center; */
    /* width: 100%; */
    /* width: 90%; */
    padding: 0 7%;
    
}
.welcome .no-touch-footer .qr-code,
.no-touch-footer .qr-code{
    /* width: 180px; */
    width: 30vw;
    /* height: 180px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcome .no-touch-footer .cam-prev-sec,
.no-touch-footer .cam-prev-sec {
    /* width: 180px; */
    width: 30vw;
    /* height: 180px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.welcome .no-touch-footer .qr-code-org,
.no-touch-footer .qr-code-org {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 10px; */
}
.welcome .scan-this,
.scan-this,
.welcome .scan-this.newtheme,
.scan-this.newtheme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 21px;
    color: #8d8d8d;
    padding-top: 10px;
    width: 180px;
    text-align: center;
    height: 70px;
}
.welcome .scan-this ion-text,
.scan-this ion-text {
    color: #8d8d8d !important;
}

.welcome .scan-this.newtheme ion-text,
.scan-this.newtheme ion-text {
    color: var(--welcome-button-text-color) !important;
}

.only-front-cam {
    display: block;
    margin: auto;
    margin-top: 10%;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;
}



.welcome .welcome-btn,
.welcome .welcome-btn-whimsical {
    margin-bottom: 35px;
    --border-radius: 50px;
    width: 450px;
    height: 75px;
    font-size: 3.5vw;
    font-weight: 700;
    --padding-start: 5px;
    --padding-end: 5px;
    /*  */
    --background: var(--welcome-button-color);
    --background-hover: var(--basic-btn-hover-color);
    --background-focused: var(--basic-btn-focused-color);
    --background-activated: var(--basic-btn-activated-color);
    --ripple-color: var(--basic-btn-ripple-color);

    color: var(--welcome-button-text-color)
}

.welcome .welcome-btn-modern {
    margin-bottom: 35px;
    --border-radius: 15px;
    width: 450px;
    height: 75px;
    font-size: 3.5vw;
    font-weight: 700;
    --padding-start: 5px;
    --padding-end: 5px;

    --background: var(--welcome-button-color);
    --background-hover: var(--basic-btn-hover-color);
    --background-focused: var(--basic-btn-focused-color);
    --background-activated: var(--basic-btn-activated-color);
    --ripple-color: var(--basic-btn-ripple-color);
    
    color: var(--welcome-button-text-color)
}

.welcome .welcome-btn-dark {
    margin-bottom: 35px;
    width: 400px;
    height: 75px;
    font-size: 3.5vw;
    font-weight: 300;
    --padding-start: 5px;
    --padding-end: 5px;
    /*  */
    --background: var(--welcome-button-color);
    --background-hover: var(--basic-btn-hover-color);
    --background-focused: var(--basic-btn-focused-color);
    --background-activated: var(--basic-btn-activated-color);
    --ripple-color: var(--basic-btn-ripple-color);

    border: 1px solid #5D5F6B;
    box-sizing: border-box;
    border-radius: 2px;
    --border-radius: 2px;

    color: var(--welcome-button-text-color)
}

.welcome .hidden-menu-button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    --padding-start: 0;
    --padding-end: 0;
}

@media (orientation: portrait) {
    .no-touch-cont-landscape {
        display: none;
    }
    .welcome .no-touch-cont-landscape .no-touch-text,
    .no-touch-cont-landscape .no-touch-text {
        display: none;
    }
}

@media (orientation: landscape) {
    .welcome-img-container {
        margin-top: var(--image-container-margin-top-landscape) !important;
    }
    .welcome .image-welcome {
        /* background-color: gray; */
        background-image: var(--welcome-background-image-landscape);
    }
    .welcome .container .title {
        font-size: 40px;
        
    }
    .welcome .welcome-btn,
    .welcome .welcome-btn-dark,
    .welcome .welcome-btn-modern,
    .welcome .welcome-btn-whimsical {
        margin-bottom: -50px !important;
        font-size: 3.5vh;
    }
    .welcome .footer-w, .footer-w,
    .welcome .footer-w.newtheme, .footer-w.newtheme {
        width: 100%;
        display: flex;
    }

    .welcome .footer-w.newtheme, 
    .footer-w.newtheme {
        padding: 5%;
        height: 42%;
    }

    .welcome .footer-w.newtheme.modern, 
    .footer-w.newtheme.modern {
        border-radius: 30px 30px 0px 0px;
    }

    
    .bottom-footer {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .only-front-cam {
        margin-top: unset !important;
    }
    .welcome .no-touch-footer,
    .no-touch-footer {
        flex-direction: row;
        /* height: 30%; */
        width: 100%;
    }
    .welcome .no-touch-text,
    .no-touch-text {
        display: none;
    }
    .welcome .no-touch-cont-landscape,
    .no-touch-cont-landscape {
        margin-top: 60px;
        width: 100%;
        text-align: center;
    }
    .welcome .no-touch-cont-landscape .no-touch-text,
    .no-touch-cont-landscape .no-touch-text {
        display: unset !important;
    }
    .welcome .container .mid-quarter {
        height: 0%;
    }

    .welcome .container .mid-quarter.newtheme {
        height: 10%;
    }

    .welcome .no-touch-cont,
    .no-touch-cont {
        display: none;
    }
    .welcome .mid-bar,
    .mid-bar,
    .welcome .mid-bar.newtheme,
    .mid-bar.newtheme {
        display: none;
    }
    .only-front-cam .scan-this,
    .scan-this {
        width: 55%;
    }

    .welcome .container .whimsical-wave, 
    .container .whimsical-wave {
        height: 65px;
        transform: rotate(180deg) translateY(-7px);
    }
}


@media screen and (max-width: 600px) {
/*use vw*/

}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){

    .welcome .container {
        width: 100%;
        height: 100%;
        z-index: 200;
        justify-content: unset;
    }
    
    .welcome .container .top-quarter {
        height: 430%;
    }
    /* .welcome .container .welcome-image {
        width: 28vh;
        height: 28vh;
        margin-top: 6vh;
        margin-bottom: unset;
    } */
    .welcome .container .title {
        font-size: 7vh;
        margin-top: 2vh;
    }

    .welcome .container .mid-quarter,
    .welcome .container .mid-quarter.newtheme {
        margin-top: 10px;
        height: 10%;
        margin-bottom: 12vh;
    }

    .welcome .welcome-btn {
        width: 60vh;
        height: 10vh;
        font-size: 3vh;
        text-transform: capitalize;
    }

    .welcome .no-touch-text,
    .no-touch-text {
        font-size: 5.5vh;
    }

    .welcome .no-touch-footer .qr-code,
    .no-touch-footer .qr-code,
    .welcome .no-touch-footer .cam-prev-sec,
    .no-touch-footer .cam-prev-sec {
        width: 37vh;
    }

    .welcome .scan-this,
    .scan-this,
    .welcome .scan-this.newtheme,
    .scan-this.newtheme {
        /* font-size: 4vh; */
        padding-top: 2vh;
    }
}

/* ANIMATION FOR BUTTONS */
.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
    /* -webkit-animation-duration: 1s; */
    -webkit-animation-duration: 5s;
    /* animation-duration: 1s; */
    animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    /* animation: wobble 5s ease-in-out infinite; */
    animation-iteration-count: infinite;
}
@-webkit-keyframes wobble {
    0% {
    -webkit-transform: none;
    transform: none;
    }
    15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
}
@keyframes wobble {
    0% {
    -webkit-transform: none;
    transform: none;
    }
    15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
}
.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    /* -webkit-animation-duration: 1s; */
    -webkit-animation-duration: 4s;
    /* animation-duration: 1s; */
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes shake {
    0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
}
@keyframes shake {
    0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
}
.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
    0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
@keyframes pulse {
    0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
}
.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    }
    40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
    }
    70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    }
    90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
    }
}

@keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    }
    40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
    }
    70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    }
    90% { -webkit-transform: translate3d(0,-4px,0); transform: translate3d(0,-4px,0);
    }
}

