.package-signature ion-content{
    width: auto;
    --background: var(--greetly-background-color);
}

.package-signature .buttons-container {
    max-width: 700px;
    max-height: 350px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}
.package-signature .yesno-button {
    width: 45vw;
    height: 55vw;
    max-height: 350px;
    max-width: 275px;
    --color: var(--color-icon);
    --border-color: var(--common-gray);
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: 0px 8px 14px var(--option-button-box-shadow-color);
    --border-radius: 20px;
    --background: var(--greetly-background-color);
    --background-hover: var(--option-button-background-hover);
    --background-focused: var(--option-button-background-focused);
    --background-activated: var(--option-button-background-activated);
    --ripple-color: var(--option-button-ripple-color);
}

.package-signature .yesno-icon {
    position: initial;
    font-size: 140px;
}

.package-signature .yesno-icon.no {
    color: var(--common-gray);
}

.package-signature .yesno-text {
    font-size: 35px;
    text-transform: capitalize;
    position: absolute;
    left: 0vw;
    bottom: 12px;
    color: var(--text-color);
}
@media screen and (max-width: 767px) {
    /*use vw*/

    .package-signature .buttons-container {
        display: flex;
        justify-content: space-evenly;
    }
    
    .package-signature ion-content{
        width: auto;
    }
    
    .package-signature .title {
        font-size: 6.7vw;
        line-height: inherit;
    }

    .package-signature .copyright {
        position: absolute;
        bottom: 0;
    }

    .package-signature .yesno-button {
        width: 45vw;
        height: 55vw;
    }

    .package-signature .yesno-icon {
        letter-spacing: -0.02em;
        font-size: 20vw;
        position: absolute;
        top: 10vw;
    }

    .package-signature .yesno-text {
        font-size: 4vw;
        text-transform: capitalize;
        position: absolute;
        left: 0vw;
        bottom: 3vw;
        color: var(--text-color);
    }
        
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){

    .package-signature .title {
        font-size: 6.7vh;
        line-height: inherit;
    }

    .package-signature .yesno-button {
        width: 35vh;
        height: 45vh;
        --border-radius: 20px;
    }

    .package-signature .yesno-icon {
        position: initial;
        font-size: 17vh;
    }

    .package-signature .yesno-text {
        font-size: 5vh;
        left: 0vh;
        bottom: 2vh;
    }
    
}