/**
Theme
.workflow-item // default
.workflow-item-modern
.workflow-item-dark
.workflow-item-whimsical
**/

.workflow-item:first-child,
.workflow-item-modern:first-child {
  grid-column: 1;
  grid-row: 1 / 3;
}

.icon-block {
  height: auto;
}

.workflow-item {
  z-index: 9000;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: transparent;
  position: relative;
  bottom: -1000px;
  transition: bottom 0.25s ease;
  padding: 5px;
  filter: drop-shadow(-4px 5px 8px #00000060);
}

.workflow-button .workflow-label {
  padding-top: 5px;
  font-size: 1.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: initial;
  overflow: hidden;
}

.workflow-item .workflow-button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--basic-btn-color);
  color: var(--color);
  border-radius: 25px;
  padding: 5px;
  overflow: hidden;
}

.workflow-item-modern {
  z-index: 9000;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: transparent;
  position: relative;
  bottom: -1000px;
  transition: bottom 0.25s ease;
  padding-left: 10px;
  padding-right: 10px;
  filter: drop-shadow(-4px 3px 5px #00000020);
}

.workflow-item-dark {
  z-index: 9000;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  --padding-start: 0;
  --inner-padding-end: 0;
  position: relative;
  padding: 5px;
}

.workflow-item-whimsical {
  z-index: 9000;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: transparent;
  position: relative;
  bottom: -1000px;
  transition: bottom 0.25s ease;
  padding: 2px;
  filter: drop-shadow(-4px 3px 5px #00000020);
}

.workflow-item.bottom,
.workflow-item-modern.bottom,
.workflow-item-dark.bottom,
.workflow-item-whimsical.bottom {
  bottom: 0;
}

.workflow-item-modern .ellipse-back,
.workflow-item-whimsical .ellipse-back {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2px;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  padding: 2px;
}

.workflow-item-modern .ellipse,
.workflow-item-whimsical .ellipse {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 54px;
  border-radius: 50%;
}

.workflow-item-modern .ellipse-back {
  background: var(--button-icon-color-transparent);
}

.workflow-item-modern .ellipse {
  position: relative;
  background: var(--button-icon-color);
}

.workflow-item-whimsical .ellipse-back {
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px 2px rgba(124, 124, 124, 0.15);
}

.workflow-item-whimsical .ellipse {
  position: relative;
  background-image: linear-gradient(127.82deg, var(--button-icon-color) 30.82%, var(--color-icon-shade) 101.86%);
}

.workflow-item .options-icon {
  font-size: 50px;
  color: #ffffff;
  --gr-color-icon: #ffffff;
}

.workflow-item-modern .options-icon,
.workflow-item-whimsical .options-icon {
  position: relative;
  font-size: 26px;
  color: #ffffff;
  --gr-color-icon: #ffffff;
  display: table-cell;
  vertical-align: middle;
}

.workflow-item-dark .options-icon {
  font-size: 40px;
  color: var(--button-icon-color);
  --gr-color-icon: var(--button-icon-color);
  margin-left: auto;
  margin-right: auto;
  margin: 10px;
}

.workflow-item .options-icon.small,
.workflow-item-modern .options-icon.small,
.workflow-item-whimsical .options-icon.small {
  display: none;
}

.workflow-item ion-label {
  --color: #ffffff;
  color: #ffffff;
  position: initial;
  height: 90%;
  width: 100%;
  line-height: normal;
  text-overflow: unset !important;
  white-space: initial !important;
  text-align: initial;
}

.workflow-item-modern ion-label,
.workflow-item-whimsical ion-label {
  --color: var(--button-text-color) !important;
  color: var(--button-text-color) !important;
}

.workflow-item .text-format {
  display: flex !important;
  flex-wrap: wrap;
  align-content: flex-end;
  --color: #ffffff;
  color: #ffffff;
}

.workflow-item-modern .text-format {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  color: #000000;
}

.workflow-item-whimsical .text-format {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  color: var(--basic-btn-color);
}

.workflow-item-dark .text-format {
  text-align: center;
  color: var(--workflow-button-text-color);
}

.workflow-item-whimsical .workflow-button {
  margin-left: 2px;
  margin-right: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--button-button-color);
  border: 1px solid var(--button-button-color);
  box-sizing: border-box;
  box-shadow: 1px 1px 2px 0px rgba(124, 124, 124, 0.15);
  border-radius: 30px;
  padding: 5px;
}

.workflow-item-dark .workflow-button {
  width: 100%;
  height: 100%;
  background: #343746;
  background-color: #343746;
  border: 1px solid #5d5f6b;
  box-sizing: border-box;
  border-radius: 1px;
  background: var(--button-button-color);
}

.workflow-item .gradient-button {
  z-index: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 25px;
  background: linear-gradient(220deg, #00000030 40%, transparent 100%);
}

.workflow-item-modern .gradient-button,
.workflow-item-whimsical .gradient-button {
  visibility: hidden;
}

.workflow-item .word {
  line-height: initial;
  text-transform: capitalize;
  align-self: flex-end;
}

.workflow-item .icon-shadow,
.workflow-item-modern .icon-shadow,
.workflow-item-whimsical .icon-shadow {
  position: absolute;
  top: 0;
  right: 0;
}

.workflow-item .icon-shadow .options-icon,
.workflow-item-modern .icon-shadow .options-icon,
.workflow-item-whimsical .icon-shadow .options-icon {
  position: absolute;
  right: -20px;
  top: -30px;
  font-size: 150px;
  opacity: 0.1;
  color: #ffffff;
  --gr-color-icon: #ffffff;
}

/*TABLET Portrait*/
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .workflow-item,
  .workflow-item-modern,
  .workflow-item-whimsical {
    margin: 0;
    display: flex;
    box-sizing: border-box;
    --padding-start: 0;
    --inner-padding-end: 0;
    --background: transparent;
  }

  .workflow-item ion-label {
    --color: #ffffff;
    position: initial;
    height: 90%;
    width: 100%;
    line-height: normal;
    text-overflow: unset !important;
    white-space: initial !important;
    text-align: initial;
  }

  .workflow-item-modern ion-label,
  .workflow-item-whimsical ion-label {
    position: relative;
    width: 100%;
    text-overflow: unset !important;
    white-space: initial !important;
  }
}

/*PHONE*/
@media screen and (max-width: 767px) {
  .workflow-item-whimsical .ellipse-back {
    margin: 0;
  }

  .workflow-item:first-child,
  .workflow-item-modern:first-child,
  .workflow-item-whimsical:first-child,
  .workflow-item-dark:first-child {
    grid-column: unset;
    grid-row: unset;
  }

  .workflow-item-modern,
  .workflow-item-whimsical {
    min-height: 120px;
  }

  .workflow-item-dark {
    min-height: 120px;
  }

  .workflow-item .options-icon,
  .workflow-item-modern .options-icon,
  .workflow-item-whimsical .options-icon {
    position: unset;
    right: 0;
    top: 0;
    font-size: 1.2rem;
    color: #ffffff;
    --gr-color-icon: #ffffff;
  }

  .workflow-item-modern .options-icon,
  .workflow-item-whimsical .options-icon {
    position: unset;
    right: 0;
    top: 0;
    /* font-size: 5vw; */
    color: #ffffff;
    --gr-color-icon: #ffffff;
  }

  .workflow-item ion-label {
    opacity: 1;
    height: unset;
    padding-left: 3vw;
    padding-right: 0;
    --text-color: var(--ion-color-primary-contrast);
    max-height: 69px;
    flex-wrap: wrap;
    align-content: unset;
  }

  .workflow-item-modern ion-label,
  .workflow-item-whimsical ion-label {
    --text-color: var(--ion-color-primary-contrast);
    height: 100px;
    max-height: 100px;
    text-align: left;
  }

  .workflow-item .workflow-button {
    width: 100%;
    height: 100%;
    --border-radius: 4vw;
    --box-shadow: 0px 1vw 2vw var(--option-button-box-shadow-color);
  }

  .workflow-item-modern .workflow-button .workflow-item-whimsical .workflow-button {
    padding: 2.5vw;
    width: 100%;
    height: 100%;
    --border-radius: 4vw;
    --box-shadow: 0px 1vw 2vw var(--option-button-box-shadow-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .workflow-item-whimsical .workflow-button,
  .workflow-item-modern .workflow-button {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 0;
  }

  .workflow-item .gradient,
  .workflow-item-modern .gradient,
  .workflow-item-whimsical .gradient {
    z-index: -1;
    height: 100%;
    background: linear-gradient(10deg, #00000031 15%, transparent 60%);
  }

  .workflow-item .gradient-button {
    top: 0;
    left: 0;
  }

  .workflow-item-modern .gradient-button,
  .workflow-item-whimsical .gradient-button {
    visibility: hidden;
  }

  .workflow-item-modern .ellipse-back {
    background: var(--basic-btn-color-transparent);
  }

  .workflow-item-modern .ellipse {
    border-radius: 50%;
    background: var(--basic-btn-color);
  }

  .workflow-item-modern .text-format {
    display: flex !important;
    flex-wrap: wrap;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    vertical-align: middle;
    color: #000000;
  }

  .workflow-item-whimsical .text-format {
    display: flex !important;
    flex-wrap: wrap;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    vertical-align: middle;
  }
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2) {
  .workflow-item,
  .workflow-item-modern,
  .workflow-item-whimsical {
    transition: bottom 0.25s ease-out;
    padding: 5px;
  }

  .workflow-item .workflow-button {
    padding: 1vh;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .workflow-item .gradient-button,
  .workflow-item-modern .gradient-button,
  .workflow-item-whimsical .gradient-button {
    border-radius: 3vh;
    top: 0;
    left: 0;
  }

  .workflow-item .options-icon {
    right: 2vh;
    top: 1.8vh;
    font-size: 7vh;
  }

  .workflow-item ion-label {
    height: 90%;
    width: 100%;
  }
}

.workflow-button .workflow-label.fs-1_0_rem {
  font-size: 1rem;
}

.workflow-button .workflow-label.fs-1_1_rem {
  font-size: 1.1rem;
}

.workflow-button .workflow-label.fs-1_2_rem {
  font-size: 1.2rem;
}

.workflow-button .workflow-label.fs-1_3_rem {
  font-size: 1.3rem;
}

.workflow-button .workflow-label.fs-1_4_rem {
  font-size: 1.4rem;
}

.workflow-button .workflow-label.fs-1_5_rem {
  font-size: 1.5rem;
}

.workflow-button .workflow-label.fs-1_6_rem {
  font-size: 1.6rem;
}

.workflow-button .workflow-label.fs-1_8_rem {
  font-size: 1.8rem;
}

.workflow-button .workflow-label.fs-2_1_rem {
  font-size: 2.1rem;
}

.workflow-button .display-none {
  display: none;
}

@media screen and (max-width: 767px) {
  .workflow-button .workflow-label {
    font-size: 1.3rem;
    text-align: unset;
    padding: 0 10px;
  }

  .workflow-button .workflow-label {
    display: flex;
    text-align: center;
    /* justify-content: flex-start; */
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .workflow-button .workflow-label {
    text-align: unset;
  }

  .workflow-item-modern .ellipse-back,
  .workflow-item-whimsical .ellipse-back {
    width: 54px;
    height: 54px;
  }

  .workflow-item-modern .ellipse,
  .workflow-item-whimsical .ellipse {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 350px) {
  .workflow-button .workflow-label {
    width: calc(100% - 70px);
    font-size: 1.2rem;
    text-align: unset;
  }
}
