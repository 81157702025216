.icon-block {
  align-items: flex-end;
  display: flex;
}

.text-container {
  color: var(--button-text-color);
}

.workflow-button.bottom {
  overflow: hidden;
}

.workflow-item-whimsical,
.workflow-item-modern {
  .workflow-modern-wrapper {
    width: 100%;
    height: 100%;
    background: var(--button-button-color);
    border: 1px solid var(--button-button-color);
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 0px rgba(124, 124, 124, 0.15);
    border-radius: 15px;
    margin-left: 2px;
    margin-right: 2px;
    overflow: hidden;

    .icon-block {
      height: 70px;
      justify-content: center;
      align-items: flex-end;
      display: flex;
      margin-bottom: 10px;
    }

    .ellipse-back,
    .ellipse {
      height: 65px;
      width: 65px;
    }

    .text-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .workflow-button {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modern-element {
    flex: 1 1 auto;
  }
}

.workflow-item-whimsical {
  .workflow-modern-wrapper {
    .ellipse-back {
      height: 69px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: landscape) {
  .workflow-item-modern {
    .workflow-modern-wrapper {
      .icon-block {
        height: 66px;
      }

      .ellipse-back,
      .ellipse {
        height: 60px;
        width: 60px;
      }
    }
  }

  .workflow-item-whimsical {
    .workflow-modern-wrapper {
      .ellipse-back {
        height: 63px;
      }
      .ellipse {
        height: 60px;
        width: 60px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .workflow-item-whimsical,
  .workflow-item-modern {
    .workflow-modern-wrapper {
    }

    .modern-element {
      flex: none;
    }

    .text-container {
      .workflow-label {
        justify-content: flex-start;

        text-align: start;
        align-items: flex-start;
      }
    }
  }
}
