.success ion-content{
    width: auto;
    --background: var(--greetly-background-color);
}

.success ion-item {
    --background: var(--greetly-background-color);
}

.success .image {
    max-width: 500px;
    max-height: 500px;
    width: 85vw;
    margin: 0 auto;
    height: 85vw;
    margin-top: 15vw;
}
@media (orientation: landscape) {
    .success .image {
        margin-top: unset;
    }
    
}

.success .failed.img {
    height: 20vw;
    margin: 20vw auto;
}
@media (orientation: landscape) {
    .success .failed.img {
        height: 10vw;
    margin: 15vw auto;
    }
    
}

.success .welcome {
    margin: 0;
    color: var(--text-color);
    font-size: 30px;
    text-align: center;
    white-space: unset;
    background-color: var(--greetly-background-color);
}

.success .notification {
    margin: 0;
    text-align: center;
    color: var(--color-header-text);
    font-size: 50px;
    font-weight: bold;
    white-space: unset;
    line-height: 9vw;
    z-index: 99999;
}

.success .exit {
    display: block;
    margin: 5vw auto;
    /* width: 25vw; */
    /* --border-radius: 10vw; */
    --background: var(--button-button-color);
    --background-activated: var(--basic-btn-activated-color);
    --background-focused: var(--basic-btn-focused-color);
    --background-hover: var(--basic-btn-hover-color);
    --ripple-color: var(--basic-btn-ripple-color);
    font-weight: bold;
    /*  */
    text-transform: capitalize;
    height: 55px;
    width: 235px;
    --padding-start: 20px;
    --padding-end: 20px;
    --border-radius: 30px;
    font-size: 25px;
    color: var(--button-text-color);
    /* margin: auto; */
}

.success .copyright {

}

@media screen and (max-width: 767px) {
    .success .image {
        width: 85vw;
        margin: 0 auto;
        height: 85vw;
    }
    
    .success .welcome {
        margin: 5vw 0;
        font-size: 5vw;
        text-align: center;
        white-space: unset;
    }
    
    .success .notification {
        font-size: 8vw;
        line-height: 9vw;
        z-index: 99999;
        font-weight: 100;
    }
    
    .success .exit {
        display: block;
        margin: 10vw auto;
        width: 25vw;
        --border-radius: 10vw;
        font-weight: bold;
    }
    
    .success .copyright {
        position: absolute;
        bottom: 5vw;
    }
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){

    .success .image {
        width: 50vh;
        margin: 0 auto;
        height: 50vh;
    }
    
    .success .welcome {
        margin: 4vh 0;
        font-size: 5vh;
        text-align: center;
        white-space: unset;
        line-height: inherit;
    }
    
    .success .notification {
        font-size: 6vh;
        line-height: 6.5vh;
        font-weight: 100;
    }
    
    .success .exit {
        margin: 5vh auto;
        width: 40vh;
        --border-radius: 10vh;
    }
    
}