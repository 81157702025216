.grant-permissions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.grant-permissions .container {
    width: 70vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.grant-permissions .container .img {
    width: 35vw;
}

.grant-permissions .container .form {
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.grant-permissions .container .submit {
    display: block;
    margin: 0 auto;
    width: 30vw;
    height: 7vw;
    --background-hover: var(--option-button-background-hover);
    --background-focused: var(--option-button-background-focused);
    --background-activated: var(--option-button-background-activated);
    --ripple-color: var(--option-button-ripple-color);
    --border-radius: 25vw;
    --box-shadow: 0px 8px 14px var(--option-button-box-shadow-color);
    font-size: 30px;
    --background: #f7941e;
    --color: #ffffff;
}
.grant-permissions .container .title {
    color: #8d8d8d;
    font-size: 30px;
    text-align: center;
}


@media screen and (max-width: 600px) {
    /*use vw*/
    .grant-permissions .container .submit {
        font-size: 5vw;
    }
       
}
