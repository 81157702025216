.scan ion-content{
    width: auto;
    --background: var(--greetly-background-color);
    position: relative;
}

.scan .barcode-scanner-container {
    position: absolute;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
}

.scan .barcode-scanner-container-new-design {
    position: absolute;
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
}

.scan .barcode-scanner-container .btn-container {
    background: black;
    padding-bottom: 20px;
    padding-top: 20px;
}

.scan .barcode-scanner {
    background: black;
    flex: 1;
    display: flex;
}

.scan .skip {
    height: 50px;
}

@media (orientation: landscape) {
    .scan .barcode-scanner-container {
        height: calc(70% - var(--image-container-margin-top-landscape)) !important;
    }
    
    .scan .barcode-scanner-container-new-design {
        height: calc(85% - var(--image-container-margin-top-landscape)) !important;
    }
}