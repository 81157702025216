.inputs {
    max-width: 1000px;
    margin: 0 auto;
    width: 85%;
    margin-top: 20px;
}

.inputs .input,
.inputs .input-margin {
    background: none;
    z-index: 0;
    /* --highlight-color-focused: var(--input-active-color); */
    
    border-color: var(--input-element-color);
    /* --background-focused: none; */
    font-size: 35px;
    position: relative;
}

.inputs .input input {
    --padding-bottom: 0;
    width: 100%;
    height: 50px;
    border: solid var(--input-element-color);
    border-width: 0 0 3px 0;
    padding-left: 20px;
    color: var(--input-focus-color);
}

.inputs .input-margin input {
    --padding-bottom: 0;
    width: 100%;
    height: 50px;
    border: solid var(--input-element-color);
    border-width: 0 0 3px 0;
    padding-left: 85px;
    color: var(--input-focus-color);
}

.inputs .input-margin.warning input {
    --padding-bottom: 0;
    width: 100%;
    height: 50px;
    border: solid red;
    border-width: 0 0 3px 0;
    padding-left: 85px;
    color: red;
}

.inputs .input-margin input.warning {
    /* color: red; */
}

.inputs .input input .focused,
.inputs .input-margin input .focused {
    --padding-bottom: 0;
    width: 100%;
    height: 50px;
    border: solid var(--input-element-color);
    border-width: 0 0 3px 0;
    padding-left: 20px;
}

.inputs .input .options-icon,
.inputs .input-margin .options-icon {
    color: var(--input-element-color);
    /* --color-icon: var(--input-element-color); */
    position: absolute;
    bottom: 10px;
    right: 20px;
}

.inputs .placeholder {
    pointer-events: none;
    color: var(--input-element-color);
    z-index: 11;
    display: block;
    /* font-size: 14px; */
    font-size: 25px;
    padding-top: 10px;
    /* transform: translateY(-35px) translateX(15px) scale(1.25); */
    transform: translateY(-55px) translateX(15px) scale(1.25);
}

.inputs .placeholder-margin {
    pointer-events: none;
    color: var(--input-element-color);
    z-index: 11;
    display: block;
    /* font-size: 14px; */
    font-size: 25px;
    padding-top: 10px;
    /* transform: translateY(-35px) translateX(15px) scale(1.25); */
    transform: translateY(-55px) translateX(85px) scale(1.25);
}


.inputs .input input:focus,
.inputs .input-margin input:focus {
    color: var(--input-focus-color);
    border-color: var(--input-active-color);
}

.inputs .focused {
    color: var(--input-active-color);
    opacity: 1;        
    transform: translateY(0) scale(1);
}

.inputs .filled {
    color: var(--input-element-color);
    transform: translateY(0) scale(1);
}

.inputs .filled.warning {
    color: red !important;
}
 
.input.item-has-focus {
    --border-color: var(--input-active-color);
}

.inputs .iconfocus {
    color: var(--input-active-color) !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    color: transparent;
}

input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: red !important;
}

.inputs.warning {
    --color: red !important;
}
.inputs.warning input {
    --color: red !important;
    border-bottom: 1px solid red;
}

@media screen and (max-width: 600px) {
    .inputs {
        /* margin: 2vw 2vw; */
    }
    .inputs .input .options-icon,
    .inputs .input-margin .options-icon {
        bottom: 13px;
        font-size: 30px;

    }
    .input.ios .options-icon,
    .input-margin.ios .options-icon {
        right: 2.5vw;
    }
    .inputs .placeholder {
        font-size: 4vw;
        /* transform: translateY(-7vw) translateX(4vw) scale(1.25); */
        transform: translateY(-12vw) translateX(4vw) scale(1.25);
    }
    .inputs .native-input,
    .inputs .native-input-margin {
        font-size: 5vw;
        padding-right: 65px;
        
    }
    .inputs .focused {
        color: var(--input-active-color);
        opacity: 1;
        transform: translateY(-1vw) scale(1);
    }
    .inputs .filled {
        color: var(--input-element-color);
        transform: translateY(0) scale(1);
    }
    .input.item-has-focus,
    .input-margin.item-has-focus {
        --border-color: var(--input-active-color);
    }
}
