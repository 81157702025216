.visitor ion-content{
    width: auto;
    --background: var(--greetly-background-color);
}

.visitor .porsche-logo {
    margin-top: 105px;
    height: 154px;
    margin-bottom: 90px;
}

/* .visitor .title {
    --background: none;
    font-family: 'Porsche Next TT';
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 102px;
    text-align: center;
    letter-spacing: -0.01em;
} */
.visitor .title {
    overflow: hidden;
    /* height: 10%; */
    /* max-height: 120px; */
    /* height: 15%;
    max-height: 120px; */
    /* opacity: 0; */
}

.visitor .title ion-label {
    white-space: unset;
}

.visitor .center-box {
    display: flex;
    align-items: top;
    justify-content: center;
}

.visitor .center-box .country-code {
    position: absolute;
    margin-top: 26px;
    margin-left: 20px; 
    width: 85%;
    --PhoneInputCountryFlag-height: 30px;
    max-width: 1000px;
}

@media (orientation: landscape) {
    .visitor .title {
        /* font-size: 4em;
        line-height: unset;
        overflow: hidden;
        height: 10%;
        max-height: 120px; */
        font-size: 3em;
        line-height: unset;
        overflow: hidden;
        /* height: 6em; 
        max-height: 120px; */
    }
/* 
    .visitor .country-code {
        margin-left: 14.1vw;
    } */
    
}

@media screen and (max-width: 600px) {
    /*use vw*/
    .visitor ion-content{
        width: auto;
    }

    .visitor .porsche-logo {
        margin-top: 10vw;
        height: 24vw;
        margin-bottom: 10vw;
    }
    
    .visitor .title {
        font-size: 6.7vw;
        line-height: inherit;
    }

    .visitor .country-code {
        /* margin-left: 11vw; */
        --PhoneInputCountryFlag-height: 25px;
    }
        
}

.visitor .country-code input {
    visibility: hidden;
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){

    .visitor .title {
        font-weight: 300;
        font-size: 5vh;
        line-height: inherit;
        text-align: center;
        letter-spacing: -0.01em;
        height: auto;
    }
    .visitor .title ion-label{
        margin: 0;
    }

    .visitor .end-block {
        height: 60vh;
    }
    .visitor .end-block.hide{
        display: none;
    }

}

.visitor ion-content{
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.inputs .input input {
    -webkit-transform: translateZ(0px);
}
.visitor input {
    background: none;
}