.banner-img-cont-full {
  position: absolute;
  width: 100%;
  height: 75%;
  overflow: hidden;
  mask-size: cover;
  /* background-color: var(--banner-color-background); */
  /* mask-image: linear-gradient(180deg, var(--ion-background-color), #ffffffff, #ffffff66, #ffffff00); */
}

@media (orientation: landscape) {
  .banner-img-cont-full {
    height: 75%;
  }
}

.banner-img-full {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.35;
  top: 0;
  position: relative;
}

/* .gradient-cont {
    position: absolute;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, var(--ion-background-color), #ffffff00);
    bottom: 0;
} 
.gradient-img {
    position: relative;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, var(--ion-background-color), #ffffff00);
    top: -50%;
}  */

@media screen and (max-width: 600px) {
  /*use vw*/

  .banner-img-cont-full {
    position: absolute;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  .banner-img-full {
    height: auto;
    width: 100%;
    object-fit: cover;
    opacity: 0.3;
    top: 0;
  }
}
