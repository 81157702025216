@mixin workflow_template($r_start, $r_end, $c_start: false, $c_end: false) {
  grid-row-start: $r_start;
  grid-row-end: $r_end;

  @if $c_start {
    grid-column-start: $c_start;
    grid-column-end: $c_end;
  }
}

#workflowsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, minmax(0, 1fr)));
  margin: 0 auto;
  height: 100%;

  &.grid-template-2 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    & > div:nth-child(1) {
      @include workflow_template(1, 3, 1, 3);
    }

    & > div:nth-child(2) {
      @include workflow_template(1, 3, 3, 5);
    }
  }

  &.grid-template-3 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 5, 1, 3);
    }

    & > div:nth-child(2) {
      @include workflow_template(1, 3, 3, 5);
    }

    & > div:nth-child(3) {
      @include workflow_template(3, 5, 3, 5);
    }
  }

  &.grid-template-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 3, 1, 3);
    }

    & > div:nth-child(2) {
      @include workflow_template(3, 5, 1, 3);
    }

    & > div:nth-child(3) {
      @include workflow_template(1, 3, 3, 5);
    }

    & > div:nth-child(4) {
      @include workflow_template(3, 5, 3, 5);
    }
  }

  &.grid-template-5 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 7);
    }

    & > div:nth-child(2) {
      @include workflow_template(1, 4, 2, 3);
    }

    & > div:nth-child(3) {
      @include workflow_template(4, 7, 2, 3);
    }

    & > div:nth-child(4) {
      @include workflow_template(1, 4, 3, 4);
    }

    & > div:nth-child(5) {
      @include workflow_template(4, 7, 3, 4);
    }
  }

  &.grid-template-6 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 4);
    }

    & > div:nth-child(2) {
      @include workflow_template(4, 7);
    }

    & > div:nth-child(3) {
      @include workflow_template(1, 4, 2, 3);
    }

    & > div:nth-child(4) {
      @include workflow_template(4, 7, 2, 3);
    }

    & > div:nth-child(5) {
      @include workflow_template(1, 4, 3, 4);
    }

    & > div:nth-child(6) {
      @include workflow_template(4, 7, 3, 4);
    }
  }

  &.grid-template-7 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 4);
    }

    & > div:nth-child(2) {
      @include workflow_template(4, 7);
    }

    & > div:nth-child(3) {
      @include workflow_template(1, 4, 2, 3);
    }

    & > div:nth-child(4) {
      @include workflow_template(4, 7, 2, 3);
    }

    & > div:nth-child(5) {
      @include workflow_template(1, 3, 3, 4);
    }

    & > div:nth-child(6) {
      @include workflow_template(3, 5, 3, 4);
    }

    & > div:nth-child(7) {
      @include workflow_template(5, 7, 3, 4);
    }
  }

  &.grid-template-8 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 4);
    }

    & > div:nth-child(2) {
      @include workflow_template(4, 7);
    }

    & > div:nth-child(3) {
      @include workflow_template(1, 3, 2, 3);
    }

    & > div:nth-child(4) {
      @include workflow_template(3, 5, 2, 3);
    }

    & > div:nth-child(5) {
      @include workflow_template(5, 7, 2, 3);
    }

    & > div:nth-child(6) {
      @include workflow_template(1, 3, 3, 4);
    }

    & > div:nth-child(7) {
      @include workflow_template(3, 5, 3, 4);
    }

    & > div:nth-child(8) {
      @include workflow_template(5, 7, 3, 4);
    }
  }

  &.grid-template-9 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));

    & > div:nth-child(1) {
      @include workflow_template(1, 3);
    }

    & > div:nth-child(2) {
      @include workflow_template(3, 5);
    }

    & > div:nth-child(3) {
      @include workflow_template(5, 7);
    }

    & > div:nth-child(4) {
      @include workflow_template(1, 3, 2, 3);
    }

    & > div:nth-child(5) {
      @include workflow_template(3, 5, 2, 3);
    }

    & > div:nth-child(6) {
      @include workflow_template(5, 7, 2, 3);
    }

    & > div:nth-child(7) {
      @include workflow_template(1, 3, 3, 4);
    }

    & > div:nth-child(8) {
      @include workflow_template(3, 5, 3, 4);
    }

    & > div:nth-child(9) {
      @include workflow_template(5, 7, 3, 4);
    }
  }

  &.grid-template-10 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    & > div:nth-child(1) {
      @include workflow_template(1, 5);
    }

    & > div:nth-child(2) {
      @include workflow_template(5, 9);
    }

    & > div:nth-child(3) {
      @include workflow_template(9, 13);
    }

    & > div:nth-child(4) {
      @include workflow_template(1, 5, 2, 3);
    }

    & > div:nth-child(5) {
      @include workflow_template(5, 9, 2, 3);
    }

    & > div:nth-child(6) {
      @include workflow_template(9, 13, 2, 3);
    }

    & > div:nth-child(7) {
      @include workflow_template(1, 4, 3, 4);
    }

    & > div:nth-child(8) {
      @include workflow_template(4, 7, 3, 4);
    }

    & > div:nth-child(9) {
      @include workflow_template(7, 10, 3, 4);
    }

    & > div:nth-child(10) {
      @include workflow_template(10, 13, 3, 4);
    }
  }

  &.grid-template-11 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    & > div:nth-child(1) {
      @include workflow_template(1, 5);
    }

    & > div:nth-child(2) {
      @include workflow_template(5, 9);
    }

    & > div:nth-child(3) {
      @include workflow_template(9, 13);
    }

    & > div:nth-child(4) {
      @include workflow_template(1, 4, 2, 3);
    }

    & > div:nth-child(5) {
      @include workflow_template(4, 7, 2, 3);
    }

    & > div:nth-child(6) {
      @include workflow_template(7, 10, 2, 3);
    }

    & > div:nth-child(7) {
      @include workflow_template(10, 13, 2, 3);
    }

    & > div:nth-child(8) {
      @include workflow_template(1, 4, 3, 4);
    }

    & > div:nth-child(9) {
      @include workflow_template(4, 7, 3, 4);
    }

    & > div:nth-child(10) {
      @include workflow_template(7, 10, 3, 4);
    }

    & > div:nth-child(11) {
      @include workflow_template(10, 13, 3, 4);
    }
  }

  &.grid-template-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    & > div:nth-child(1) {
      @include workflow_template(1, 4);
    }

    & > div:nth-child(2) {
      @include workflow_template(4, 7);
    }

    & > div:nth-child(3) {
      @include workflow_template(7, 10);
    }

    & > div:nth-child(4) {
      @include workflow_template(10, 13);
    }

    & > div:nth-child(5) {
      @include workflow_template(1, 4, 2, 3);
    }

    & > div:nth-child(6) {
      @include workflow_template(4, 7, 2, 3);
    }

    & > div:nth-child(7) {
      @include workflow_template(7, 10, 2, 3);
    }

    & > div:nth-child(8) {
      @include workflow_template(10, 13, 2, 3);
    }

    & > div:nth-child(9) {
      @include workflow_template(1, 4, 3, 4);
    }

    & > div:nth-child(10) {
      @include workflow_template(4, 7, 3, 4);
    }

    & > div:nth-child(11) {
      @include workflow_template(7, 10, 3, 4);
    }

    & > div:nth-child(12) {
      @include workflow_template(10, 13, 3, 4);
    }
  }
}

@media screen and (max-width: 767px) {
  #workflowsContainer {
    display: flex;
    padding: 10px;
    justify-content: start;

    .workflow-button .workflow-label {
      width: 100%;
    }
  }
}
