.workflow-container {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;

  .text-container {
    .workflow-label {
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 100%;
      padding-top: 0;
      align-items: center;
    }
  }

  .image-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-button-color-2nd);
    border-right: 1px solid #5d5f6b;
    font-size: 3rem;
  }

  .text-container {
    flex: 1;
    width: calc(100% - 160px);
    height: 100%;
    --color: var(--button-text-color) !important;
    color: var(--button-text-color) !important;

    .label-text {
      overflow-wrap: normal;
      word-break: keep-all;
    }
  }
}
