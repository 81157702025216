.hidden-menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden-menu .hidden-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
}
.hidden-menu .hidden-status {
  padding: 20px;
  width: 100%;
  height: auto;
  color: var(--text-color);
}
.hidden-menu .hidden-btn {
  flex: auto;
  width: 50%;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 1px;
  --background: var(--basic-btn-color);
  --background-hover: var(--basic-btn-hover-color);
  --background-focused: var(--basic-btn-focused-color);
  --background-activated: var(--basic-btn-activated-color);
  --ripple-color: var(--basic-btn-ripple-color);
}

.hidden-menu .hidden-btn.active {
  --background: #f49220;
}

.hidden-menu .hidden-barcode-scanner-prev {
  height: 400px;
  width: 100%;
  background: black;
}

.hidden-menu .hidden-container ion-label {
  color: var(--text-color);
}
