.error-fallback {
	display: flex;
	align-items: center;
	justify-content: center;
}

.error-fallback-header {
	padding: 15px;
	margin-bottom: 25px;
	text-align: center;
}

.error-fallback-button {
	padding: 15px;
	text-align: center;
}