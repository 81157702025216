.search-host {
  @media screen and (orientation: landscape) and (min-aspect-ratio: 7 / 3) {
    .app-screen-title-new-design.modern,
    .app-screen-title-new-design.dark,
    .app-screen-title-new-design.whimsical {
      margin-top: 5px;

      ion-label {
        font-size: 4.5vh;
      }
    }

    .inputs {
      position: relative;
      margin: 10px auto;

      .placeholder {
        transform: translateY(-9px) translateX(50px) scale(1.25);
        z-index: 9999999;
        position: absolute;
        bottom: 0;
        margin-bottom: -20px;
        font-size: 2.5 vh;
      }

      .input {
        margin: 5px auto 0;
        width: 90%;
      }
    }
  }
}

.whimsical-wave {
  z-index: 0;
}
