.search-host ion-content{
    width: auto;
    --background: none;
    --keyboard-offset: 0;
    background-color: var(--greetly-background-color);
}

@media (orientation: landscape) {
    .search-host .title {
        font-size: 3.5em;
        line-height: unset;
        overflow: hidden;
        height: 18%;
        max-height: 120px;
    }
    .search-host .inputs {
        margin-top: unset !important;
    }
}

.search-host .employee-list-container {
    width: 100vw;
    margin: 0 auto;
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    min-height: 220px;
    z-index: 100;
}

.search-host .copyright {
    display: block;
    text-align: center;
    width: 100%;
    --min-height: 20px;
}

/* .search-host .cancel-button .options-icon{
    font-size: 25px;
} */

.search-host .inputs .input {
    margin-top: 30px;
    font-size: 41px;
    z-index: 2;
}

@media screen and (max-width: 600px) {
    /*use vw*/
        .search-host ion-content{
            width: auto;
        }
        
        .search-host .title {
            font-size: 6.7vw;
            line-height: inherit;
        }
        
        .search-host .employee-list-container {
            width: 95vw;
            flex-direction: column;
        }

        .workflows .copyright {
            bottom: 0;
        }
}

/*TABLET widescreen 16/9*/
@media screen and (orientation: landscape) and (min-aspect-ratio: 3/2){

    .search-host .title {
        font-weight: 300;
        font-size: 5vh;
        line-height: inherit;
        text-align: center;
        letter-spacing: -0.01em;
        height: auto;
    }
    .search-host .title ion-label{
        margin: 0;
    }

    .search-host .inputs {
        margin-top: 0;
        z-index: 2;
    }

    .search-host .inputs .input {
        --border-width: 0 0 2px 0;
        --border-color: var(--input-element-color);
    }

    .search-host .inputs .input.item-has-focus {
        --border-color: var(--input-active-color);
    }

    .search-host .inputs .placeholder {
        font-size: 3vh;
        padding-top: 1vh;
        transform: translateY(-6vh) translateX(15px) scale(1.25);
        z-index: 2;
    }

    .search-host .inputs .focused {
        color: var(--input-active-color);
        opacity: 1;
        transform: translateY(0) scale(1);
    }

    .search-host .inputs .filled {
        color: var(--input-element-color);
        transform: translateY(0) scale(1);
    }

    .search-host .employee-list-container {
        margin: 0 !important;
    }
}