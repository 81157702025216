.stub-loading ion-content{
    width: auto;
    --background: none;
}

@media screen and (max-width: 600px) {
    /*use vw*/
        .stub-loading ion-content{
            width: auto;
        }        
}