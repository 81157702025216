.slider {
    width: 85%;
    display: block;
    max-width: 1000px;
    margin: 0px auto;
    --color: var(--input-element-color);
    --background: none;
    z-index: 0;
    --highlight-color-focused: var(--input-active-color);
    /* --border-width: 0 0 3px 0; */
    /* --border-color: var(--input-element-color); */
    /* --background-focused: none; */
    font-size: 35px;
}

.slider .slider_row {
    padding-top: 40px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

 .slider .name {
    --color: var(--input-element-filled-color);
    white-space: unset !important;
    color: var(--input-element-filled-color);
    z-index: 11;
    display: block;
    font-size: 31.25px !important;
    padding-top: 10px;
    text-transform: capitalize;
}

/*.slider.warning {
    color: red;
    --border-color: red;
}
.slider.warning ion-toggle {
    --background: red;
}
.slider.warning  .name {
    color: red;
} */



@media screen and (max-width: 767px) {
    .slider {
        margin: 6vw auto;
    }
    
    .slider .name {
        --color: var(--input-element-color);
        font-size: calc(4vw * 1.25) !important;
    }
    
    .slider ion-toggle {
        --background: var(--input-element-color);
        padding-right: 0; 
    }
    
    .slider.ios ion-toggle {
        --handle-width: 5vw;
        padding-right: 0;
        height: 6vw;
        width: 11vw;
    }
}