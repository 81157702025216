.toggle {
    width: 85%;
    display: block;
    max-width: 1000px;
    margin: 60px auto;
    --color: var(--input-element-color);
    --background: none;
    z-index: 0;
    --highlight-color-focused: var(--input-active-color);
    --border-width: 0 0 3px 0;
    --border-color: var(--input-element-color);
    --background-focused: none;
    font-size: 35px;
}

.toggle .name {
    --color: var(--input-element-filled-color);
    white-space: unset !important;
    color: var(--input-element-filled-color);
    z-index: 11;
    display: block;
    font-size: 31.25px !important;
    padding-top: 10px;
    text-transform: capitalize;
}

.toggle ion-toggle {
    --background: var(--input-element-color);
    padding-right: 0; 
}

.toggle.ios ion-toggle {
    --handle-width: 26px;
    padding-right: 0;
    height: 30px;
    width: 55px;
}

.toggle.warning {
    color: red;
    --border-color: red;
}
.toggle.warning ion-toggle {
    --background: red;
}
.toggle.warning  .name {
    color: red;
}



@media screen and (max-width: 767px) {
    .toggle {
        margin: 6vw auto;
    }
    
    .toggle .name {
        --color: var(--input-element-color);
        font-size: calc(4vw * 1.25) !important;
    }
    
    .toggle ion-toggle {
        --background: var(--input-element-color);
        padding-right: 0; 
    }
    
    .toggle.ios ion-toggle {
        --handle-width: 5vw;
        padding-right: 0;
        height: 6vw;
        width: 11vw;
    }
}