.web-barcode-scanner {
    width: 100%;
    height: 100%;
    display: flex;
}

.web-barcode-scanner .error {
    flex: 1;
    color: white;
    text-align: center;
    margin: auto;
    overflow-wrap: anywhere;
}

.web-barcode-scanner .preview {
    flex: 1;
    position: relative;
}

.web-barcode-scanner .preview video {
    width: 100%;
    height: 100%;
    position: absolute;
}

.web-barcode-scanner .preview .mask {
    position: absolute;
    height: 100%;
}

.web-barcode-scanner .preview .mask .sub0 {
    position: absolute;
    width: 100%;
    background-color: #00000055;
}

.web-barcode-scanner .preview .mask .sub1 {
    position: absolute;
    width: 100%;
    background-color: #00000055;
}

.web-barcode-scanner .preview .mask .line {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
}