.radioselect {
    max-width: 1000px;
    width: 85%;
    margin: 20px auto;
}

.radioselect .title-radio {
    margin: 0 auto;
    font-size: 35px;
    font-weight: initial;
    text-align: left;
    display: block;
    max-width: 1000px;
    line-height: initial;
    /* height: min(40px, 5vw); */
    color: var(--text-color);
    /* font-family: var(--ion-font-family); */
}

.radioselect ion-item {
    margin: 0 auto;
    --color: var(--input-element-color);
    font-size: 30px;
    border-bottom: 1px solid var(--input-element-color);
}

.radioselect ion-item ion-label {
    white-space: initial !important;
}

.radioselect ion-list-header {
    padding: 0;
}

.radioselect ion-radio {
    --color: var(--input-element-color);
}

.radioselect.warning {
    --color: red !important;
}
.radioselect.warning ion-item {
    --color: red !important;
    border-bottom: 1px solid red;
}
.radioselect.warning ion-radio {
    --color: red;
}

@media screen and (max-width: 767px) {
    .radioselect .title {
        margin: 0 auto;
        font-size: min(5vw, 25px);
        text-align: left;
        display: block;
        max-width: 1000px;
        line-height: 1;
        /* text-align: center; */
        letter-spacing: -0.04em;
    }

    .radioselect ion-item {
        margin: 0 auto;
        --color: var(--input-element-color);
        font-size: min(5vw, 25px);
        border-bottom: 1px solid var(--input-element-color);
    }
}