.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

ion-toggle {
    margin-right: 15px;
}

.login .container {
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.login .container .img {
    width: 50%;
    margin-top: 2vw;
}

.login .container .title {
    color: #8d8d8d;
    font-size: 3vw;
    /* margin-top: 5%; */
}

.login .login-pin-cont {
    height: 30%;
    width: 60%;
}

.login .container .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.login input {
	display: block;
    /* margin: 2em auto; */
    border: none;
    padding: 0;
    width: 7ch;
    background: repeating-linear-gradient(90deg, 
    #8d8d8d 0, #8d8d8d 1ch, 
    transparent 0, transparent 1.5ch) 
    0 100%/ 10ch 4px no-repeat;
    font: 7ch droid sans mono, consolas, monospace;
    letter-spacing: .5ch;
    text-transform: uppercase;
}
input:focus {
    outline: none;
    color: #000000;
}

.login .wrongCode{
    color: red;
    position: relative;
    /* bottom: 50px; */
}

.login .wrongCode.hide{
    display: none;
}
@media (orientation: landscape) {
    .login .container .img {
        width: 35%;
        margin-top: unset;
    }

}
@media screen and (max-width: 600px) {
    /*use vw*/
   

}
